<template>
    <div v-if="isLoading">
        <div class="text-center text-danger screen-center" style="padding-top: 20%;">
            <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
            <strong>Cargando...</strong>
        </div>
    </div>
    <div v-else>
        <div class="breadcrumb">
            <h1>
                <span>Datos Empresa</span>
            </h1>
        </div>
        <!-- modificacion de edicion de empresa -->
        <div class="separator-breadcrumb border-top"></div>
        <div class="row mb-4">
            <div class="col-md-12 mb-4">
                <div class="card text-left">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-sm-6">
                                <template v-if="!editMode">
                                    <button type="button" 
                                    class="btn th-custom-color m-1" @click="editMode = true">
                                        Modificar
                                    </button>
                                </template>
                                <template v-else>
                                    <button class="btn th-custom-color m-1"
                                    type="button" @click="update()">Guardar</button>
                                    <button type="button" class="btn btn-secondary m-1" @click="editMode = false">
                                        Cancelar
                                    </button>
                                </template>
                            </div>
                            <div class="col-sm-6 text-right">
                                <p> {{ dominio }} <strong> ID - </strong> {{ empresaIntegracion }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group row">
                                    <label for="nombre_comercial" class="col-sm-4 col-form-label text-right">Nombre comercial: <span
                                        class="text-danger">*</span></label>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control" placeholder="Ingrese el Nombre Comercial" id="nombre_comercial"
                                            :disabled="true" v-model="nombreComercial"
                                            required>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="rubro" class="col-sm-4 col-form-label text-right">Rubro: <span
                                        class="text-danger">*</span></label>
                                    <div class="col-sm-8">
                                        <multiselect
                                            id="rubro"
                                            track-by="descripcion"
                                            label="descripcion"
                                            placeholder="Seleccione un Rubro"
                                            :options="rubros"
                                            :disabled="!editMode"
                                            deselectLabel=""
                                            selectLabel=""
                                            :searchable="true">
                                        </multiselect>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="categoria" class="col-sm-4 col-form-label text-right">Categoria: <span
                                        class="text-danger">*</span></label>
                                    <div class="col-sm-8">
                                        <multiselect
                                            id="categoria"
                                            track-by="descripcion"
                                            label="descripcion"
                                            placeholder="Seleccione una categoria"
                                            :options="categorias"
                                            :disabled="!editMode"
                                            deselectLabel=""
                                            selectLabel=""
                                            :searchable="true">
                                        </multiselect>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group row">
                                    <label for="razon_social" class="col-sm-4 col-form-label text-right">Razon Social: <span
                                        class="text-danger">*</span></label>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control"
                                        :disabled="true" v-model="razonSocial"
                                        >
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="nit" class="col-sm-4 col-form-label text-right">NIT: <span
                                        class="text-danger">*</span></label>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control"
                                        :disabled="true" v-model="nit"
                                        >
                                    </div>
                                </div>
                                <div class="form-group row" v-if="medicoId">
                                    <label for="tercero" class="col-sm-4 col-form-label text-right">Tercero:</label>
                                    <div class="col-sm-3">
                                        <input type="text" class="form-control"
                                        placeholder="Numero"
                                        v-model="numeroMedico"
                                        :disabled="true"
                                        >
                                    </div>
                                    <div class="col-sm-5">
                                        <input type="text" class="form-control"
                                        placeholder="Nombre"
                                        v-model="razonSocialMedico"
                                        :disabled="true"
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <b-col md="12">
                                    <b-tabs
                                    class="tabNav"
                                    active-nav-item-class="nav nav-tabs"
                                    content-class="mt-3">
                                        <b-tab title="Empresa" active>
                                            <TheTabEmpresa
                                            :editMode="editMode"/>
                                        </b-tab>
                                        <b-tab title="Proveedor">
                                            <TheTabProveedor
                                            :editMode="editMode"
                                            />
                                        </b-tab>
                                        <b-tab title="Activar">
                                            <TheTabActivar
                                            :editMode="editMode"
                                            />
                                        </b-tab>
                                        <b-tab title="Diseños">
                                            <TheTabDisenio
                                            :editMode="editMode"/>
                                        </b-tab>
                                    </b-tabs>
                                </b-col>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import 'vue2-datepicker/locale/es';
import 'vue2-datepicker/index.css';
import Multiselect from 'vue-multiselect'

import TheTabEmpresa from '../components/TheTabEmpresa.vue';
import TheTabProveedor from '../components/TheTabProveedor.vue';
import TheTabDisenio from '../components/TheTabDisenio.vue';
import TheTabActivar from '../components/TheTabActivar.vue';
import { call, get, sync } from 'vuex-pathify';

export default {
    name:'edit',
    data() {
        return {
            editMode: false,
            rubros: [],
            categorias: [],
        };
    },
    created() {
        this.getDatInicial();
    },
    methods: {
        ...call('datosEmpresa/edit', [
            'getDatInicial',
            'update',
        ]),
    },
    computed: {
        ...sync('datosEmpresa/edit', [
            'nombreComercial',
            'razonSocial',
            'nit',
            'isLoading',   
            'razonSocialMedico',
            'numeroMedico',
            'medicoId'
        ]),
        ...get('datosEmpresa/edit', [
            'empresaIntegracion',
        ]),
        ...get('empresa/generateCodigoIntegracion', [
            'dominio'
        ]),
    },
    beforeDestroy() {
        this.$store.dispatch('datosEmpresa/edit/reset');
    },
    components: {
        Multiselect,
        TheTabEmpresa,
        TheTabProveedor,
        TheTabDisenio,
        TheTabActivar,
    },
}
</script>