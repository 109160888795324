import { make } from "vuex-pathify";
import axios from '@/modules/common/axiosFacturacion';
import { util } from '@/plugins/util';

const state = () => ({
    estado: true,
    page: 1,
    porPagina: 100,
    paginaOpciones: [100, 200],
    filter: null,
    delayTimeout: null,
    fields: [
        { key: 'pdf', label: ' ', class: 'text-center', thStyle: { width: '3%' }, },
        { key: 'eliminar', label: ' ', class: 'text-center', thStyle: { width: '3%' }, },
        { key: 'numero', label: 'N°', class: 'text-center', thStyle: { width: '3%' }, },
        { key: 'codigo_global', label: 'Codigo', class: 'text-center', thStyle: { width: '3%' }, },
        { key: 'fecha', label: 'Fecha', class: 'text-center', thStyle: { width: '10%' }, },
        { key: 'sucursal', label: 'Sucursal', class: 'text-center', thStyle: { width: '10%' }, },
        { key: 'cliente', label: 'Cliente', class: 'text-center', thStyle: { width: '20%' }, },
        { key: 'importe', label: 'Importe', class: 'text-center', thStyle: { width: '10%' }, },
        { key: 'moneda', label: 'Moneda', class: 'text-center', thStyle: { width: '10%' }, },
        { key: 'nro_factura', label: 'N° Fac', class: 'text-center', thStyle: { width: '3%' }, },
        { key: 'tipo_emision', label: 'Emision', class: 'text-center', thStyle: { width: '12%' }, },
        { key: 'tipo_factura', label: 'T/Fac.', class: 'text-center', thStyle: { width: '3%' }, },
        { key: 'user', label: 'Usuario', class: 'text-center', thStyle: { width: '8%' }, },
    ],
    tablaFacturacions: [],
    loading: false,
    total: null,
    motivo: null,
    motivoAnulacion: [],
});

const mutations = make.mutations(state);

const getters = {};

const actions = {
    async getIndex({state, commit}) {
        try {
            commit("SET_LOADING", true)
            const param = {
                estado: state.estado ? 1 : 0,
                filter_key: state.filter,
                page: state.page,
                per_page: state.porPagina,
            };
            const { data } = await axios.get('siat/compraventa/ventas', { params: param });
            const LIST_TABLAS = data.data.data;
            LIST_TABLAS.forEach((element, index) => {
                if (element.tipo_emision === 'FUERA DE LINEA') {
                    LIST_TABLAS[index]._rowVariant = 'danger';
                }
            });
            commit("SET_TABLA_FACTURACIONS", LIST_TABLAS)
            commit("SET_TOTAL", data.data.total)
        } catch (error) {
            util.showNotify(error.response.data.message, 'error');                
        }finally{
            commit("SET_LOADING", false)
        }
    },
    async getCreate({ commit }) {
        try {
            commit("SET_LOADING", true)            
            const { data } = await axios.get('siat/compraventa/ventas/motivo');
            commit("SET_MOTIVO_ANULACION", data.data.motivo_anulacion)
        } catch (error) {
            util.showNotify(error.response.data.message, 'error');                
        }finally{
            commit("SET_LOADING", false)
        }
    },
    async setDelete({ commit, dispatch, state }, id) {
        try {
            if(await dispatch("validarDelete")) {
                commit("SET_LOADING", true)           
                const params = {
                    siat_motivoanulacion_id: state.motivo.id
                } 
                const { data } = await axios.delete('siat/compraventa/ventas/' + id, { params: params });
                util.showNotify(data.message, 'success');
                commit("SET_MOTIVO", null)
                dispatch("getIndex")
            }
        } catch (error) {
            util.showNotify(error.response.data.message, 'error');                
        }finally{
            commit("SET_LOADING", false)
        }
    },
    validarDelete({state}) { 
        let valido = true;
        if (!state.motivo) {
            util.showNotify('El Motivo Anulacion es un campo requerido', 'error');
            valido = false;
        }
  
        return valido;
      }
};

export default {
    namespaced: true,
    state, 
    mutations,
    getters,
    actions,
}